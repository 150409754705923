export function B_E_N_P_DETAILS_TOPIC (props) {
    return (
        <div className="topic">
            <div className="label">{ props?.label }</div>
            <div className="value">{ props?.value }</div>
        </div>
    )
}

export function DATE_RANGES ({ children }) {
    return (
        <div className="date-ranges">{ children }</div>
    )
}