// third-party components and hooks
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// styles
import "../styles/form_pages.css"

// components
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_PAGE_ID } from "../components/navigations";

// reducers
import { update_POI } from "../integration/reducers/event";

export default function PAGE_UPDATE_PERSON () {
    // state holders
    const params = useParams()
    const dispatch = useDispatch()
    const initialPersonDetails = useState(JSON.parse(sessionStorage.getItem("personDetails")))
    const formData = useState({ data: {
        type: initialPersonDetails[0].type,
        name: initialPersonDetails[0].name,
        description: initialPersonDetails[0].description,
        photoUrl: initialPersonDetails[0].photoUrl,
        occupation: initialPersonDetails[0].occupation,
        isPublished: initialPersonDetails[0].isPublished,
        contact: initialPersonDetails[0].contact
    } })

    console.log(`initialPersonDetails: `, initialPersonDetails[0]);
    console.log(`params: `, params?.id);

    // HANDLERS
    const HANDLER_SUBMIT_FORM = (action) => {
        action.preventDefault();

        // return console.log(formData[0]);
        dispatch(update_POI({ id: initialPersonDetails[0]._id, body: formData[0].data }))
    }

    return (
        <LAYOUT_DEFAULT pageName={ "update_person" }>
            <NAVIGATION_PAGE_ID pageName={ `Update Person` } hierarchy={ [`Dashboard`, `Events`, `People`, `Update Person`] } />
            <section className="title">Update Person</section>
            {
                params?.id?.split(`:`)[1] === initialPersonDetails[0]._id ?
                <FORM_CONTAINER context={ formData }>
                    <INPUT_FIELD label={ `Name` } initialValue={ initialPersonDetails[0].name } required={ true } placeholder={ `Enter the person's name` } type={ `text` } name={ `name` } id={ `name` } />
                    <INPUT_FIELD label={ `Role` } initialValue={ initialPersonDetails[0].type } required={ true } type={ `radio` } name={ `type` } id={ `type` } options={ ['MAIN_SPEAKER', 'GUEST_SPEAKER', 'ORGANIZER', 'VOLUNTEER'] } />
                    <INPUT_FIELD label={ `Description` } initialValue={ initialPersonDetails[0].description } required={ true } placeholder={ `Enter a brief description` } type={ `text` } name={ `description` } id={ `description` } />
                    <INPUT_FIELD label={ `Contact` } initialValue={ initialPersonDetails[0].contact } required={ true } placeholder={ `Enter mobile number` } type={ `tel` } name={ `contact` } id={ `contact` } />
                    <INPUT_FIELD label={ `Occupation` } initialValue={ initialPersonDetails[0].occupation } placeholder={ `Enter occupation` } type={ `text` } name={ `occupation` } id={ `occupation` } />
                    <INPUT_FIELD label={ `Achievements` } initialValue={ initialPersonDetails[0].achievements } placeholder={ `List relevant achievements` } type={ `text` } name={ `achievements` } id={ `achievements` } />
                    <INPUT_FIELD label={ `Picture` } required={ true } type={ `file` } name={ `photoUrl` } id={ `photoUrl` } />
                    <BUTTON icon={ `add` } label={ `Add Person` } callback={ HANDLER_SUBMIT_FORM } />
                </FORM_CONTAINER> : null
            }
        </LAYOUT_DEFAULT>
    )
}