// hooks, libraries and packages
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit'

// helper functions
import { SERVE_REQUEST } from '../routes/request_submitter';

// extra reducers/ actions
export const authenticated_user = createAsyncThunk(
    "user/authenticated_user",
    async () => {
        let response;

        try {
            response = SERVE_REQUEST(`users/me`, {
                headers: { 'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }` }
            })
        } catch (error) { console.log(`error: `, error); }

        return response;
    }
)
export const get_attendees = createAsyncThunk(
    `user/get_attendees`,
    async () => {
        let response;

        try {
            response = SERVE_REQUEST(`users/list?isActive=${ true }&page=${ 1 }&size=${ 15 }&filters=${ `PERSONAL` }&searchTerm=${ `nim` }`, {
                headers: { 'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`, },
            })
        } catch (error) { console.log(`error: `, error); }

        return response;
    }
)
export const get_vendors = createAsyncThunk(
    `user/get_vendors`,
    async () => {
        let response;

        try {
            response = SERVE_REQUEST(`users/list?isActive=${ true }&page=${ 1 }&size=${ 15 }&filters=${ `BUSINESS` }`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                    'Content-Type': 'application/json'
                },
                'transactionId': window.localStorage.getItem("transactionID"),
            })
        } catch (error) { console.log(`error: `, error); }

        return response;
    }
)
export const sign_in = createAsyncThunk(
    "user/sign_in",
    async (data, thunkAPI) => {
        let response;

        try {
            response = await SERVE_REQUEST(`auth/login`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                method: "post",
                body: new URLSearchParams(data)
            })

            if (response) {
                window.localStorage.setItem("bearerToken", response.data.accessToken);
                window.localStorage.setItem("transactionID", response.transactionId);
    
                thunkAPI.dispatch(authenticated_user(response.data.accessToken))
            }
        } catch (error) { console.log(`error: `, error); }

        return response;
    }
)

export const user = createSlice({
    name: 'user',
    initialState: {
        authenticatedUser: null,
        attendees: [],
        isLoading: false,
        signInConfirmation: null,
        vendors: [],
    },
    reducers: {
        sign_out: (state) => {
            state.authenticatedUser = null
            
            window.localStorage.removeItem("bearerToken");
            window.localStorage.removeItem("transactionID");

            window.location = `/`
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authenticated_user.fulfilled, (state, action) => {
            state.authenticatedUser = action.payload;
            console.log(action.payload);

            // redirect to dashboard if success
            if (action.payload?.statusCode === 200) {
                window.location = `/dashboard`
            }
        })
        builder.addCase(get_attendees.fulfilled, (state, action) => { action.payload?.data?.docs ? state.attendees = action.payload.data?.docs : state.attendees = []; })
        builder.addCase(get_vendors.fulfilled, (state, action) => { action.payload?.data?.docs ? state.vendors = action.payload.data?.docs : state.vendors = []; })
        builder.addCase(sign_in.fulfilled, (state, action) => { state.signInConfirmation = action.payload; })

        // match all actions
        builder.addMatcher(isFulfilled, (state) => { state.isLoading = false });
		builder.addMatcher(isPending, (state) => { state.isLoading = true });
    }
})

// Action creators are generated for each case reducer function
export const { sign_out } = user.actions
export default user.reducer