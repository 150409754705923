/* eslint no-use-before-define: 0 */  // --> OFF
// third-party components and hooks
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// styles
import "../styles/page_b_e_n_p.css";

// components
import { CARD_B_E_N_P } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_FILTER_BAR, NAVIGATION_PAGE_ID, NAVIGATION_PAGINATION } from "../components/navigations";
import { B_E_N_P_DETAILS_TOPIC } from "../components/shared";
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";

// reducers
import { get_sponsors } from "../integration/reducers/event";

export default function PAGE_BRANDS () {
    // state holders
    const context = useState();
    const [ currentPage, setCurrentPage ] = useState(1);
    const dispatch = useDispatch();
    const { sponsors } = useSelector((state) => state.event);
    const [ selectedBrand, setSelectedBrand ] = useState(null)

    // handlers
    const HANDLER_FETCH_BATCH = (pageNumber) => {
        // fetch batch code here
        return setCurrentPage(pageNumber)
    }
    const HANDLER_BRAND_CLICK = (key) => { return  setSelectedBrand(sponsors[key]) } 
    const HANDLER_SEARCH = (action) => {
        action.preventDefault();
    }

    // watchers
    // eslint-disable-next-line
    useEffect(() => { dispatch(get_sponsors()) }, [])
    // eslint-disable-next-line
    useEffect(() => { setSelectedBrand(sponsors[0]) }, [sponsors])

    return (
        <LAYOUT_DEFAULT pageName={ "b_e_n_p" }>
            <NAVIGATION_PAGE_ID pageName={ `Brands` } hierarchy={ [`Dashboard`, `Brands`] }>
                <Link to={ `/add_brand` } className="cta add">
                    <div className="icon add"></div>
                    Add Brand
                </Link>
            </NAVIGATION_PAGE_ID>
            <NAVIGATION_FILTER_BAR>
                <div className="search">
                    <FORM_CONTAINER context={ context }>
                        <div className="fieldset">
                            <BUTTON icon={ `search` } callback={ HANDLER_SEARCH } />
                            <INPUT_FIELD type={ `search` } name={ `input_search` } placeholder={ `Search Vendors` } />
                        </div>
                    </FORM_CONTAINER>
                </div>
                <div className="ctas">
                    <div className="cta">
                        <div className="icon filter"></div>
                        Filters
                    </div>
                </div>
            </NAVIGATION_FILTER_BAR>
            <section className="data">
                <div className="list">
                    {
                        sponsors.length > 0 ? sponsors?.map((brand, key) => {
                            return (
                                <div key={ key } onClick={ () => HANDLER_BRAND_CLICK(key) }>
                                    <CARD_B_E_N_P name={ brand?.name } selected={ selectedBrand?._id === brand?._id ? true : false } />
                                </div>
                            )
                        }) : null
                    }
                    
                    <NAVIGATION_PAGINATION min={ true } callback={ HANDLER_FETCH_BATCH } currentPage={ currentPage } size={ 15 } total={ sponsors?.length } />
                </div>
                
                <div className="details">
                    {
                        selectedBrand ?
                        <>
                            <div className="banner">
                                {
                                    selectedBrand?.photoUrl ? <img src={ selectedBrand?.photoUrl } alt="" /> :
                                    <div className="no-pic">{ selectedBrand?.name[0].toUpperCase() }</div>
                                }
                            </div>
                            <div className="brief">
                                <B_E_N_P_DETAILS_TOPIC label="Name" value={ selectedBrand?.name } />
                                <B_E_N_P_DETAILS_TOPIC label="Contact" value={ selectedBrand?.contact } />
                                <B_E_N_P_DETAILS_TOPIC label="Description" value={ selectedBrand?.description } />
                                <B_E_N_P_DETAILS_TOPIC label="Date Joined" value={ selectedBrand?.createdAt?.split(`T`)[0] } />
                            </div>
                        </> : null
                    }
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}