// third-party components and hooks
import { useState } from "react";
import { useDispatch } from "react-redux";

// styles
import "../styles/form_pages.css"

// components
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_PAGE_ID } from "../components/navigations";

// reducers
import { add_sponsor } from "../integration/reducers/event";

export default function PAGE_ADD_BRAND () {
    // state holders
    const dispatch = useDispatch()
    const formData = useState({})

    // HANDLERS
    const HANDLER_SUBMIT_FORM = (action) => {
        action.preventDefault();

        if (formData[0].data.photoUrl) dispatch(add_sponsor(formData[0].data))            
        else dispatch(add_sponsor({ ...formData[0].data, photoUrl: ``}))
    }

    return (
        <LAYOUT_DEFAULT pageName={ "add_brand" }>
            <NAVIGATION_PAGE_ID pageName={ `Add Brand` } hierarchy={ [`Dashboard`, `Events`, `Brands`, `Add Brand`] } />
            <section className="title">Add Brand</section>
            <FORM_CONTAINER context={ formData }>
                <INPUT_FIELD label={ `Name` } required={ true } placeholder={ `Enter the brand's name` } type={ `text` } name={ `name` } id={ `name` } />
                <INPUT_FIELD label={ `Description` } required={ true } placeholder={ `Enter a brief description` } type={ `text` } name={ `description` } id={ `description` } />
                <INPUT_FIELD label={ `Contact` } required={ true } placeholder={ `Enter mobile number` } type={ `tel` } name={ `contact` } id={ `contact` } />
                <INPUT_FIELD label={ `Picture` } required={ true } type={ `file` } name={ `photoUrl` } id={ `photoUrl` } />
                <BUTTON icon={ `add` } label={ `Add Brand` } callback={ HANDLER_SUBMIT_FORM } />
            </FORM_CONTAINER>
        </LAYOUT_DEFAULT>
    )
}