// third-party components and hooks
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// styles
import "../styles/form_pages.css"

// components
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_PAGE_ID } from "../components/navigations";

// reducers
import { update_event } from "../integration/reducers/event";

export default function PAGE_UPDATE_EVENT () {
    // state holders
    const params = useParams()
    const dispatch = useDispatch()
    const initialEventDetails = useState(JSON.parse(sessionStorage.getItem("eventDetails")))
    const formData = useState({ data: {
        name: initialEventDetails[0].name,
        occurrenceType: initialEventDetails[0].occurrenceType,
        status: initialEventDetails[0].status,
        description: initialEventDetails[0].description,
        noticesValidFor: initialEventDetails[0].noticesValidFor,
        tags: initialEventDetails[0].tags,
        address1: initialEventDetails[0].address1,
        gpsLocation: initialEventDetails[0].gpsLocation,
        organizers: initialEventDetails[0].organizers,
        regions: initialEventDetails[0].regions,
        tickets: initialEventDetails[0].tickets,
        isDisabled: initialEventDetails[0].isDisabled,
        actionType: initialEventDetails[0].actionType,
        isPublished: initialEventDetails[0].isPublished
    } })

    // HANDLERS
    const HANDLER_SUBMIT_FORM = (action) => {
        action.preventDefault();

        // console.log(`tags: `, formData[0].data?.tags?.toString());
        // return console.log(`formData: `, formData[0].data);

        dispatch(update_event({
            id: initialEventDetails[0]._id,
            body: {
                ...formData[0].data,
                tags: formData[0].data?.tags?.split(`,`)
            }
        }))
    }

    return (
        <LAYOUT_DEFAULT pageName={ "update_event" }>
            <NAVIGATION_PAGE_ID pageName={ `Update Event` } hierarchy={ [`Dashboard`, `Events`, `Update Event`] } />
            <section className="title">Update Event</section>
            {
                params?.id?.split(`:`)[1] === initialEventDetails[0]._id ?
                <FORM_CONTAINER context={ formData }>
                    {/* stage 1, Event Details */}
                    <INPUT_FIELD label={ `Name` } initialValue={ initialEventDetails[0].name } required={ true } placeholder={ `Enter the brand's name` } type={ `text` } name={ `name` } id={ `name` } />
                    <INPUT_FIELD label={ `Description` } initialValue={ initialEventDetails[0].description } required={ true } placeholder={ `Enter a brief description` } type={ `text` } name={ `description` } id={ `description` } />
                    <INPUT_FIELD label={ `Occurence` } initialValue={ initialEventDetails[0].occurrenceType } required={ true } placeholder={ `Will the event span multiple days` } type={ `radio` } name={ `occurrenceType` } id={ `occurrenceType` } options={ [ `ONCE` ] } />
                    <INPUT_FIELD label={ `Tags` } initialValue={ initialEventDetails[0].tags?.toString() } placeholder={ `Enter tags a comma separated list` } type={ `text` } name={ `tags` } id={ `tags` } />
                    {/* stage 2, Images and Videos */}
                    {/* <INPUT_FIELD label={ `Fliers and Pictures` } multi={ true } required={ true } type={ `file` } name={ `gallery` } id={ `gallery` } />
                    <INPUT_FIELD label={ `Videos` } multi={ true } required={ true } type={ `file` } name={ `videos` } id={ `videos` } /> */}
                    {/* stage 3, organizers (brands) // array of id */}
                    {/* stage 4, Speakers and Volunteers // array of id  */}
                    <BUTTON icon={ `add` } label={ `Update Event` } callback={ HANDLER_SUBMIT_FORM } />
                </FORM_CONTAINER> : null
            }
        </LAYOUT_DEFAULT>
    )
}