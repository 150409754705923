// third-party components and hooks
import { useState } from "react";
import { useDispatch } from "react-redux";

// styles
import "../styles/form_pages.css"

// components
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_PAGE_ID } from "../components/navigations";

// reducers
import { add_POI } from "../integration/reducers/event";

export default function PAGE_ADD_PERSON () {
    // state holders
    const dispatch = useDispatch()
    const formData = useState({})

    // HANDLERS
    const HANDLER_SUBMIT_FORM = (action) => {
        action.preventDefault();

        if (formData[0].data.photoUrl) dispatch(add_POI(formData[0].data))            
        else dispatch(add_POI({ ...formData[0].data, photoUrl: ``}))
    }

    return (
        <LAYOUT_DEFAULT pageName={ "add_person" }>
            <NAVIGATION_PAGE_ID pageName={ `Add Person` } hierarchy={ [`Dashboard`, `Events`, `People`, `Add Person`] } />
            <section className="title">Add Person</section>
            <FORM_CONTAINER context={ formData }>
                <INPUT_FIELD label={ `Name` } required={ true } placeholder={ `Enter the person's name` } type={ `text` } name={ `name` } id={ `name` } />
                <INPUT_FIELD label={ `Role` } required={ true } type={ `radio` } name={ `type` } id={ `type` } options={ ['MAIN_SPEAKER', 'GUEST_SPEAKER', 'ORGANIZER', 'VOLUNTEER'] } />
                <INPUT_FIELD label={ `Description` } required={ true } placeholder={ `Enter a brief description` } type={ `text` } name={ `description` } id={ `description` } />
                <INPUT_FIELD label={ `Contact` } required={ true } placeholder={ `Enter mobile number` } type={ `tel` } name={ `contact` } id={ `contact` } />
                <INPUT_FIELD label={ `Occupation` } placeholder={ `Enter occupation` } type={ `text` } name={ `occupation` } id={ `occupation` } />
                <INPUT_FIELD label={ `Achievements` } placeholder={ `List relevant achievements` } type={ `text` } name={ `achievements` } id={ `achievements` } />
                <INPUT_FIELD label={ `Picture` } required={ true } type={ `file` } name={ `photoUrl` } id={ `photoUrl` } />
                <BUTTON icon={ `add` } label={ `Add Person` } callback={ HANDLER_SUBMIT_FORM } />
            </FORM_CONTAINER>
        </LAYOUT_DEFAULT>
    )
}