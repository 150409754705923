// end points
import { APIEndPoints } from "./end_points";

// let params = {
//     headers: {
//         'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
//         "Access-Control-Allow-Origin": "*"
//     }
// }, response;

// if (method === "post") params = {
// method: method,
// headers: {
//     ...params.headers,
//     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
// body: new URLSearchParams(body)
// }



// request functions
export async function SERVE_REQUEST (end_point, req_params) {
    console.log(`request params:`, req_params);
    
    try { return (await fetch(`${ APIEndPoints.BASE_URL + end_point}`, req_params)).json() }
    catch (error) { return error }
}