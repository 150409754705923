// hooks, libraries and packages
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit'

// helper functions
import { SERVE_REQUEST } from '../routes/request_submitter';

// extra reducers/ actions
    // to test
export const add_event = createAsyncThunk(
    "event/add_event",
    async (data) => {
        console.log("data: ", data);

        let response;

        try {
            response = await SERVE_REQUEST(`events/notices`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                    'transactionId': window.localStorage.getItem("transactionID"),
                    "Content-Type": "application/json",
                    'x-channel-id': 'WEB'
                },
                body: JSON.stringify({
                    ...data,
                    type: `EVENT`,
                    status: `NOT_STARTED`,
                    noticesValidFor: {
                        startDateTime: new Date(),
                        endDateTime: new Date()
                    },
                    organizers: [],
                    speakers: [],
                    volunteers: [],
                    regions: [],
                    tickets: [],
                    gpsLocation: [4.3342333, 9.4999949],
                    isDisabled: false,
                    actionType: "IN-PERSON",
                    isPublished: true,
                    gallery: [],
                    videos: []
                })
            })
        } catch (error) { console.log(`error: `, error); }

        return response;
    }
)
export const add_POI = createAsyncThunk(
    "event/add_POI",
    async (data) => {
        const response = await SERVE_REQUEST(`events/people`, {
            method: `POST`,
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                'transactionId': window.localStorage.getItem("transactionID"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...data, isPublished: true })
        })
        
        return response;
    }
)
export const add_sponsor = createAsyncThunk(
    "event/add_sponsor",
    async (data) => {
        console.log(`data: `, data);

        const response = await SERVE_REQUEST(`events/brands`, {
            method: `POST`,
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                'transactionId': window.localStorage.getItem("transactionID"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...data, isPublished: true })
        })
        
        console.log("response, ", response);
    }
)
export const delete_event = createAsyncThunk(
    "event/delete_event",
    async (id) => {
        const response = await SERVE_REQUEST(`events/notices/${ id }`, {
            method: `DELETE`,
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                'transactionId': window.localStorage.getItem("transactionID"),
                'x-channel-id': 'WEB'
            }
        })
        
        return response;
    }
)
export const delete_POI = createAsyncThunk(
    "event/delete_POI",
    async (id) => {
        const response = await SERVE_REQUEST(`events/people/${ id }`, {
            method: `DELETE`,
            headers: { 'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }` },
        })
        
        return response;
    }
)
export const get_event_details = createAsyncThunk(
    "event/get_event_details",
    async (id) => {        
        let response;

        try {
            response = await SERVE_REQUEST(`events/notices/${ id }`, {
                headers: { 'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }` }
            })
        } catch (error) { console.log(`error: `, error); }


        return response;
    }
)
export const get_event_tickets = createAsyncThunk(
    "event/get_event_tickets",
    async (id) => {
        
        let response;

        response = await SERVE_REQUEST(`events/notices/${ id }/tickets`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`
            }
        })

        return response;
    }
)
export const get_events = createAsyncThunk(
    "event/get_events",
    async ({ page, size }) => {
        let response;

        try {
            response = await SERVE_REQUEST(`events/notices?isPaginated=${ true }&page=${ page }&size=${ size }`, {
                headers: {
                    'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                    'x-channel-id': 'WEB',
                    'transactionId': window.localStorage.getItem("transactionID")
                }
            })
        } catch (error) { console.log(`error: `, error); }


        return response;
    }
)
export const get_POIs = createAsyncThunk(
    "event/get_POIs",
    async () => {
        let response;

        try {
            response = await SERVE_REQUEST(`events/people?isPublished=${ true }`, {
                headers: { 'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }` }
            })
        } catch (error) { console.log(`error: `, error); }
        
        return response;
    }
)
export const get_sponsors = createAsyncThunk(
    "event/get_sponsors",
    async () => {
        let response;

        try {
            response = await SERVE_REQUEST(`events/brands?isPublished=${ true }`, {
                headers: {
                    'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                    'Content-Type': 'application/json' 
                }
            })
        } catch (error) { console.log(`error: `, error); }
        
        return response;
    }
)
export const get_tickets = createAsyncThunk(
    "event/get_tickets",
    async () => {
        let response;

        response = await SERVE_REQUEST(`events/tickets`, {
            headers: { 'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }` }
        })
        
        return response;
    }
)
export const toggle_event_publishing = createAsyncThunk(
    `toggle_event_publishing`,
    async (id) => {
        const response = await SERVE_REQUEST(`events/notices/${ id }/publish`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                'transactionId': window.localStorage.getItem("transactionID"),
                'x-channel-id': 'WEB'
            },
        })
        
        return response;
    }
)
export const update_event = createAsyncThunk(
    "event/update_event",
    async (data) => {
        console.log("data: ", data);
        const response = await SERVE_REQUEST(`events/notices/${ data?.id }`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                'transactionId': window.localStorage.getItem("transactionID"),
                "Content-Type": "application/json",
                'x-channel-id': 'WEB'
            },
            body: JSON.stringify({
                ...data.body,
                
                type: `EVENT`
            })
        })
        
        return response;
    }
)
export const update_POI = createAsyncThunk(
    "event/update_POI",
    async (data) => {
        console.log(`data: `, data);
        const response = await SERVE_REQUEST(`events/people/${ data?.id }`, {
            method: `PUT`,
            headers: {
                'Authorization': `Bearer ${ window.localStorage.getItem("bearerToken") }`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data?.body)
        })
        
        console.log(`response: `, response);
        return response;
    }
)

export const notice = createSlice({
    name: 'notice',
    initialState: {
        event_details: null,
        event_tickets: [],
        events: [],
        isLoading: false,
        POI_details: null,
        POIs: [],
        sponsor_details: null,
        sponsors: [],
        tickets: []
    },
    reducers: {},
    extraReducers: (builder) => {
        // creates
        builder.addCase(add_event.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/events` })
        builder.addCase(add_POI.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/people` })
        builder.addCase(add_sponsor.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/brands` })
        // deletes
        builder.addCase(delete_event.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/events` })
        builder.addCase(delete_POI.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/people` })
        // gets
        builder.addCase(get_event_details.fulfilled, (state, action) => { state.event_details = action.payload.data; })
        builder.addCase(get_events.fulfilled, (state, action) => {
            state.events = action.payload?.data?.docs;
            state.event_details = state.events[0];
        })
        builder.addCase(get_POIs.fulfilled, (state, action) => {
            state.POIs = action.payload.data;
            state.POI_details = state.POIs[0];
        })
        builder.addCase(get_sponsors.fulfilled, (state, action) => {
            state.sponsors = action.payload.data;
            state.sponsor_details = state.sponsors[0];
        })
        builder.addCase(get_tickets.fulfilled, (state, action) => { state.tickets = action.payload; })
        // updates
        builder.addCase(toggle_event_publishing.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location.reload() })
        builder.addCase(update_event.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/events` })
        builder.addCase(update_POI.fulfilled, (state, action) => { if (action.payload.statusCode >= 200 && action.payload.statusCode < 300) window.location = `/people` })


        // match all actions
        builder.addMatcher(isFulfilled, (state) => { state.isLoading = false });
		builder.addMatcher(isPending, (state) => { state.isLoading = true });
    }
})

// export const { sign_in, sign_out } = user.actions;

// Action creators are generated for each case reducer function
export default notice.reducer