// third-party components and hooks
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { CARD_A_N_V } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_FILTER_BAR, NAVIGATION_PAGE_ID, NAVIGATION_PAGINATION } from "../components/navigations";
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";

// reducers
import { get_attendees } from "../integration/reducers/user";

export default function PAGE_ATTENDEES () {
    // state holders
    const context = useState();
    const dispatch = useDispatch();
    const [ currentPage, setCurrentPage ] = useState(1);
    const { attendees } = useSelector((state) => state.user);
    const [ selectedAttendees, setSelectedAttendees ] = useState([]);

    // handlers
    const HANDLER_FETCH_BATCH = (pageNumber) => {
        // fetch batch code here
        return setCurrentPage(pageNumber)
    }
    const HANDLER_SEARCH = (action) => {
        action.preventDefault();
    }
    const HANDLER_SELECT_ATTENDEE = (id) => {
        setSelectedAttendees([...selectedAttendees, id])
    }

    // watchers
    // eslint-disable-next-line
    useEffect(() => { dispatch(get_attendees()) }, [])

    console.log(`attendees: `, selectedAttendees);

    return (
        <LAYOUT_DEFAULT pageName={ "a_n_v" }>
            <NAVIGATION_PAGE_ID pageName={ `Attendees` } hierarchy={ [`Dashboard`, `Attendees`] }>
                <div className="cta export">
                    <div className="icon export"></div>
                    Export
                </div>
                <div className="cta add">
                    <div className="icon add"></div>
                    Add Attendee
                </div>
            </NAVIGATION_PAGE_ID>
            <NAVIGATION_FILTER_BAR>
                <div className="search">
                    <FORM_CONTAINER context={ context }>
                        <div className="fieldset">
                            <BUTTON icon={ `search` } callback={ HANDLER_SEARCH } />
                            <INPUT_FIELD type={ `search` } name={ `input_search` } placeholder={ `Search Attendees` } />
                        </div>
                    </FORM_CONTAINER>
                </div>
                <div className="ctas">
                    <div className="cta">
                        <div className="icon filter"></div>
                        Filters
                    </div>
                </div>
            </NAVIGATION_FILTER_BAR>
            <section className="data">
                {
                    attendees?.length > 0 ?
                    <>
                        <CARD_A_N_V heading={ true }/>
                        {
                            attendees?.map((attendee, key) => {
                                return (
                                    <div key={ key } onClick={ () => HANDLER_SELECT_ATTENDEE(attendee?._id) }>
                                        <CARD_A_N_V id={ attendee?._id }
                                                    // selected={ selectedAttendees?.indexOf(attendee?._id) > -1 ? true : null }
                                                    entity={{
                                                        name: attendee?.fullName,
                                                        email: attendee?.isActive ? "ACTIVE" : "INACTIVE"
                                                    }} phone={ attendee?.phoneNumber } dateCreated={ attendee?.createdAt?.split(`T`) } />
                                    </div>
                                )
                            })
                        }
                        <NAVIGATION_PAGINATION callback={ HANDLER_FETCH_BATCH } currentPage={ currentPage } size={ 15 } total={ attendees?.length } />
                    </>
                    : <div>No Attendee Yet</div>
                }
            </section>
        </LAYOUT_DEFAULT>
    )
}