export const NAVIGATION = [
    {   "name": "Dashboard",
        "path": "/dashboard",
        "id": "dashboard"
    }, {
        "name": "Vendors",
        "path": "/vendors",
        "id": "vendors",
        subs: [
            {   "name": "Add Vendor",
                "path": "/add_vendor",
                "id": "add_vendors"
            }
        ]
    }, {
        "name": "Attendees",
        "path": "/attendees",
        "id": "attendees"
    }, {
        "name": "Transactions",
        "path": "/transactions",
        "id": "transactions"
    }, {
        "name": "Tickets",
        "path": "/tickets",
        "id": "tickets"
    }, {
        "name": "Events",
        "path": "/events",
        "id": "events",
        subs: [
            {   "name": "Add Event",
                "path": "/add_event",
                "id": "add_event"
            }, {
                "name": "Brands",
                "path": "/brands",
                "id": "brands"
            }, {
                "name": "People",
                "path": "/people",
                "id": "people"
            }
        ]
    }, {
        "name": "Admin Users",
        "path": "/admin_users",
        "id": "admin_users"
    }, {
        "name": "Calendar",
        "path": "/calendar",
        "id": "calendar"
    }
]