// third-party components and hooks
import { useState } from "react"
import { useDispatch } from "react-redux";

// reducers
import { sign_in } from "../integration/reducers/user";

// styles
import "../styles/page_sign_in.css"

// components
import { LAYOUT_AUTH } from "../components/layouts"
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";

export default function PAGE_SIGN_IN () {
    // state holders
    const dispatch = useDispatch();
    const signInFormData = useState();

    // event 
    function HANDLER_FORGOT_PASSWORD (params) {
        
    }
    function HANDLER_SIGN_IN_FORM (action) {
        action.preventDefault();
        dispatch(sign_in(signInFormData[0].data))
    }

    return (
        <LAYOUT_AUTH pageName={ "sign_in" }>
            <section>
                <div className="greeting">
                    <div className="main">Welcome back!</div>
                    <div className="sub">Please login to access your account.</div>
                </div>
                
                <FORM_CONTAINER context={ signInFormData }>
                    <INPUT_FIELD    name={ "username" }
                                    id={ "username" }
                                    label={ "E-mail or phone number" }
                                    help={ "Enter the email address or phone number used during your sign up process." }
                                    type={ "text" }
                                    required={ true }
                                    icon="sign-in-user-icon" />
                    <INPUT_FIELD    name={ "password" }
                                    id={ "password" }
                                    label={ "Password" }
                                    type={ "password" }
                                    extraLabel={ "Forgot Password?" }
                                    extraCallback={ HANDLER_FORGOT_PASSWORD }
                                    required={ true }
                                    icon="sign-in-lock" />
                    <BUTTON callback={ HANDLER_SIGN_IN_FORM } label={ "Sign In" } />
                </FORM_CONTAINER>
            </section>
        </LAYOUT_AUTH>
    )
}