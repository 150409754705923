// hooks
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// reducers
import eventReducer from './reducers/event'
import userReducer from './reducers/user'

// configs
const persistConfig = {
	key: 'root',
	storage,
}

// init
const rootReducer = combineReducers({
	event: eventReducer,
	user: userReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	}),
	reducer: persistedReducer,
})