// third-party components and hooks
import { useState } from "react";
import { useDispatch } from "react-redux";

// styles
import "../styles/form_pages.css"

// components
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_PAGE_ID } from "../components/navigations";

// reducers
import { add_event } from "../integration/reducers/event";

export default function PAGE_ADD_EVENT () {
    // state holders
    const dispatch = useDispatch()
    const formData = useState({})

    // HANDLERS
    const HANDLER_SUBMIT_FORM = (action) => {
        action.preventDefault();

        dispatch(add_event({
            ...formData[0].data,
            tags: formData[0].data?.tags?.split(`,`),
            gpsLocation: formData[0].data.gpsLocation?.split(`,`),
        }))
    }

    return (
        <LAYOUT_DEFAULT pageName={ "add_event" }>
            <NAVIGATION_PAGE_ID pageName={ `Add Event` } hierarchy={ [`Dashboard`, `Events`, `Add Event`] } />
            <section className="title">Add Event</section>
            <FORM_CONTAINER context={ formData }>
                {/* stage 1, Event Details */}
                <INPUT_FIELD label={ `Name` } required={ true } placeholder={ `Enter the brand's name` } type={ `text` } name={ `name` } id={ `name` } />
                <INPUT_FIELD label={ `Description` } required={ true } placeholder={ `Enter a brief description` } type={ `text` } name={ `description` } id={ `description` } />
                <INPUT_FIELD label={ `Occurence` } required={ true } placeholder={ `Will the event span multiple days` } type={ `radio` } name={ `occurrenceType` } id={ `occurrenceType` } options={ [ `ONCE` ] } />
                <INPUT_FIELD label={ `Address` } required={ true } placeholder={ `Enter address` } type={ `text` } name={ `address1` } id={ `address1` } />
                <INPUT_FIELD label={ `Location` } required={ true } placeholder={ `Enter location` } type={ `text` } name={ `gpsLocation` } id={ `gpsLocation` } />
                <INPUT_FIELD label={ `Contact` } required={ true } placeholder={ `Enter mobile number` } type={ `tel` } name={ `contact` } id={ `contact` } />
                <INPUT_FIELD label={ `Tags` } placeholder={ `Enter tags a comma separated list` } type={ `text` } name={ `tags` } id={ `tags` } />
                {/* stage 2, Images and Videos */}
                {/* <INPUT_FIELD label={ `Fliers and Pictures` } multi={ true } required={ true } type={ `file` } name={ `gallery` } id={ `gallery` } />
                <INPUT_FIELD label={ `Videos` } multi={ true } required={ true } type={ `file` } name={ `videos` } id={ `videos` } /> */}
                {/* stage 3, organizers (brands) // array of id */}
                {/* stage 4, Speakers and Volunteers // array of id  */}
                <BUTTON icon={ `add` } label={ `Add Event` } callback={ HANDLER_SUBMIT_FORM } />
            </FORM_CONTAINER>
        </LAYOUT_DEFAULT>
    )
}