// third-party components and hooks
import { useState } from "react";

// styles
import "../styles/page_transactions.css";

// images
import pp from "../images/PXL_20220909_235830633.PORTRAIT.jpg"
import BANNER_CANCELLED from "../images/cancelled_banner.svg"
import BANNER_DELIVERED from "../images/delivered_banner.svg"
import BANNER_PROCESSING from "../images/processing_banner.svg"
import BANNER_SHIPED from "../images/shipped_banner.svg"

// components
import { CARD_ORDER, CARD_SUMMARY } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_FILTER_BAR, NAVIGATION_PAGE_ID, NAVIGATION_PAGINATION } from "../components/navigations";
import { DATE_RANGES } from "../components/shared";

export default function PAGE_TRANSACTIONS () {
    // state holders
    const [ currentPage, setCurrentPage ] = useState(1);

    // handlers
    const HANDLER_FETCH_BATCH = (pageNumber) => {
        // fetch batch code here
        return setCurrentPage(pageNumber)
    }

    return (
        <LAYOUT_DEFAULT pageName={ "transactions" }>
            <NAVIGATION_PAGE_ID pageName={ `Transactions` } hierarchy={ [`Dashboard`, `Transactions`] }>
                <div className="cta export">
                    <div className="icon export"></div>
                    Export
                </div>
            </NAVIGATION_PAGE_ID>

            <section className="briefing">
                <CARD_SUMMARY banner={ BANNER_PROCESSING } label={ `Processing` } main={ `1,201` } percentageChange={ `10` } actualChange={ `10` } />
                <CARD_SUMMARY banner={ BANNER_SHIPED } label={ `Shiped` } main={ `1,821` } percentageChange={ `10` } actualChange={ `10` } />
                <CARD_SUMMARY banner={ BANNER_DELIVERED } label={ `Delivered` } main={ `9,102` } percentageChange={ `10` } actualChange={ `10` } />
                <CARD_SUMMARY banner={ BANNER_CANCELLED } label={ `Cancelled` } main={ `140` } percentageChange={ `10` } actualChange={ `10` } />
            </section>

            <NAVIGATION_FILTER_BAR>
                <DATE_RANGES>
                    <div className="active">All Date</div>
                    <div>12 Months</div>
                    <div>30 Days</div>
                    <div>7 Days</div>
                    <div>24 Hours</div>
                </DATE_RANGES>

                <div className="ctas">
                    <div className="cta">
                        <div className="icon cta-calendar"></div>
                        Select Dates
                    </div>
                    <div className="cta">
                        <div className="icon filter"></div>
                        Filters
                    </div>
                </div>
            </NAVIGATION_FILTER_BAR>

            <section className="data">
                <CARD_ORDER heading={ true }/>
                <CARD_ORDER id={ `1` }
                            product={{
                                image: pp,
                                name: "Some Product",
                                others: "+2 other products"
                            }}
                            customer={{
                                name: "User X",
                                email: "x@x.com"
                            }} email={ "x@x.com" }
                            phone={ `020934213` }
                            dateCreated={ `1 min ago` } 
                            total={ `$ 121.98` } 
                            paymentMethod={ `Mastercard` } 
                            status={ `Processing` } />

                <NAVIGATION_PAGINATION callback={ HANDLER_FETCH_BATCH } currentPage={ currentPage } size={ 15 } total={ 238 } />
            </section>
        </LAYOUT_DEFAULT>
    )
}