// third-party components and hooks
import { useState } from "react";

// styles
import "../styles/form_pages.css"

// components
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_PAGE_ID } from "../components/navigations";

export default function PAGE_ADD_VENDOR () {
    // state holders
    const formData = useState({})

    return (
        <LAYOUT_DEFAULT pageName={ "add_vendor" }>
            <NAVIGATION_PAGE_ID pageName={ `Add Vendor` } hierarchy={ [`Dashboard`, `Vendors`, `Add Vendor`] } />
            <section className="title">Add Vendor</section>
            <FORM_CONTAINER context={ formData }>
                <INPUT_FIELD label={ `Business Name` } required={ true } placeholder={ `Enter Business Name` } type={ `text` } name={ `input_name` } id={ `input_name` } />
                <INPUT_FIELD label={ `Mobile Number` } required={ true } placeholder={ `Enter Mobile Number` } type={ `tel` } name={ `input_tel` } id={ `input_tel` } />
                <BUTTON icon={ `add` } label={ `Add Account` } />
            </FORM_CONTAINER>
        </LAYOUT_DEFAULT>
    )
}