//third-party components and hooks
import { createContext, useContext } from "react";

// styles
import "../styles/form_elements.css"

// contexts
export const FormContext = createContext();

export function FORM_CONTAINER ({ children, context }) {
    return (
        <FormContext.Provider value={ context }>
            <form>{ children }</form>
        </FormContext.Provider>
    )
}

// button
export function BUTTON ({ callback, icon, label }) {
    const button_click_handler = (e) => { callback(e); }

    return (
        <button onClick={ (e) => button_click_handler(e) } className="button">{
            <>
                { icon ? <div className={ `icon ${ icon }` }></div> : null }
                { label }
            </>
        }</button>
    )
}

// input field
export function INPUT_FIELD ({ id, extraCallback, extraLabel, help, icon, initialValue, label, name, options, placeholder, required, type }) {
    const [ formData, updateFormData ] = useContext(FormContext)

    return (
        <div className={ `input-field ${ type }` }>
            {
                label ?
                <div className="label">
                    <label htmlFor={ label }>{ label }</label>
                    {
                        help ?
                        <div className="help">
                            <div className="icon information"></div>
                            <div>{ help }</div>
                        </div> : null
                    }
                </div> : null
            }

            {
                type === `radio` ?
                <div className="options">
                    {
                        options?.map((option, key) => {
                            return(
                                <div    key={ key }
                                        className={
                                            formData?.data && (formData?.data[name] === initialValue === option) ? `selected` :
                                                initialValue === option && !(formData?.data && formData?.data[name]) ? `selected` :
                                                    formData?.data && formData?.data[name] === option ? `selected` : null
                                        }
                                        onClick={ () => updateFormData({ ...formData, data: { ...formData?.data, [ name ]: option } }) }>
                                    { option?.replace(`_`, ` `) }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
            {
                
                type === `textarea` ?
                <textarea name={ name } id="id" defaultValue={ initialValue } placeholder={ placeholder } required={ required } onChange={ ({ target }) => updateFormData({ ...formData, data: { ...formData?.data, [ target?.name ]: target?.value } }) }></textarea> :
                <>
                    {
                        type === `file` ?
                        <div onClick={ () => { document.getElementById(id).click() } } className="file-uploader">
                            <div>Upload File</div>
                            {/* { formData?.data[ name ] ?  <div>{ formData?.data[ name ][0]?.name }</div> : null } */}
                        </div> : null
                    }
                    <div className="keyarea">
                        { icon ? <div className={ `icon ${ icon }` }></div> : null }
                        <input  type={ type }
                                placeholder={ placeholder }
                                name={ name }
                                defaultValue={ initialValue }
                                id={ id }
                                required={ required }
                                onChange={
                                    ({ target }) => updateFormData({
                                        ...formData,
                                        data: {
                                            ...formData?.data,
                                            [ target?.name ]: type === `file` ? target?.files : target?.value
                                        }
                                    })
                                } />
                    </div>
                </>
            }

            {
                extraLabel ?
                <div onClick={ extraCallback } className="extraLabel">{ extraLabel }</div> : null
            }
        </div>
    )
}