// third-party components and hooks
import { BrowserRouter, Route, Routes } from "react-router-dom";

// components
import PAGE_ADD_BRAND from "./pages/add_brand";
import PAGE_ADD_EVENT from "./pages/add_event";
import PAGE_ADD_PERSON from "./pages/add_person";
import PAGE_ADD_VENDOR from "./pages/add_vendor";
import PAGE_ADMIN_USERS from "./pages/admin_users";
import PAGE_ATTENDEES from "./pages/attendees";
import PAGE_BRANDS from "./pages/brand";
import PAGE_CALENDAR from "./pages/calendar";
import PAGE_DASHBOARD from "./pages/dashboard";
import PAGE_EVENTS from "./pages/events";
import PAGE_NOT_FOUND from "./pages/not_found";
import PAGE_PEOPLE from "./pages/people";
import PAGE_SIGN_IN from "./pages/sign_in";
import PAGE_TICKETS from "./pages/tickets";
import PAGE_TRANSACTIONS from "./pages/transactions";
import PAGE_UPDATE_EVENT from "./pages/update_event";
import PAGE_UPDATE_PERSON from "./pages/update_person";
import PAGE_VENDORS from "./pages/vendors";
import { NAVIGATION_PROTECTED_ROUTE } from "./components/navigations";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				{/* public routes */}
				<Route path="/" Component={ PAGE_SIGN_IN }/>
				<Route path="/sign_in" Component={ PAGE_SIGN_IN }/>

				{/* protected routes */}
				<Route Component={ NAVIGATION_PROTECTED_ROUTE }>
					<Route path="/add_brand" Component={ PAGE_ADD_BRAND }/>
					<Route path="/add_event" Component={ PAGE_ADD_EVENT }/>
					<Route path="/add_person" Component={ PAGE_ADD_PERSON }/>
					<Route path="/add_vendor" Component={ PAGE_ADD_VENDOR }/>
					<Route path="/admin_users" Component={ PAGE_ADMIN_USERS }/>
					<Route path="/attendees" Component={ PAGE_ATTENDEES }/>
					<Route path="/brands" Component={ PAGE_BRANDS }/>
					<Route path="/calendar" Component={ PAGE_CALENDAR }/>
					<Route path="/dashboard" Component={ PAGE_DASHBOARD }/>
					<Route path="/events" Component={ PAGE_EVENTS }/>
					<Route path="/people" Component={ PAGE_PEOPLE }/>
					<Route path="/tickets" Component={ PAGE_TICKETS }/>
					<Route path="/transactions" Component={ PAGE_TRANSACTIONS }/>
					<Route path="/update_event/:id" Component={ PAGE_UPDATE_EVENT }/>
					<Route path="/update_person/:id" Component={ PAGE_UPDATE_PERSON }/>
					<Route path="/vendors" Component={ PAGE_VENDORS }/>
				</Route>

				{/* page not found */}
				<Route path="*" Component={ PAGE_NOT_FOUND } />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
