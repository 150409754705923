// third-party components and hooks
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

// styles
import './styles/index.css';
import './styles/variables.css';
import './styles/themes.css';

// components
import App from './App';

// helpers
import { store } from './integration/store'
import reportWebVitals from './reportWebVitals';

// app initialions import
let persistor = persistStore(store)

// clear pevious session data
localStorage.removeItem('persist:root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={ persistor }>
				<App />
			</PersistGate>     
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
