/* eslint no-use-before-define: 0 */  // --> OFF
// third-party components and hooks
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// styles
import "../styles/page_b_e_n_p.css";
import "../styles/page_events.css";

// components
import { CARD_B_E_N_P } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_FILTER_BAR, NAVIGATION_PAGE_ID, NAVIGATION_PAGINATION } from "../components/navigations";
import { B_E_N_P_DETAILS_TOPIC } from "../components/shared";
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";

// reducers
import { delete_POI, get_POIs } from "../integration/reducers/event";

export default function PAGE_PEOPLE () {
    // state holders
    const context = useState();
    const [ currentPage, setCurrentPage ] = useState(1);
    const dispatch = useDispatch();
    const { POIs } = useSelector((state) => state.event);
    const [ selectedPerson, setSelectedPerson ] = useState(``)

    // handlers
    const HANDLER_DELETE_PERSON = (id) => {
        dispatch(delete_POI(id))
        dispatch(get_POIs())
    }
    const HANDLER_FETCH_BATCH = (pageNumber) => {
        // fetch batch code here
        return setCurrentPage(pageNumber)
    }
    const HANDLER_PERSON_CLICK = (key) => { return setSelectedPerson(POIs[key]) } 
    const HANDLER_SEARCH = (action) => {
        action.preventDefault();
    }
    const HANDLER_UPDATE_PERSON = (action) => {
        sessionStorage.setItem("personDetails", JSON.stringify(selectedPerson));
        return window.location = `/update_person/:${ selectedPerson?._id }`
    }

    // watchers
    // eslint-disable-next-line
    useEffect(() => { dispatch(get_POIs()) }, [])
    // eslint-disable-next-line
    useEffect(() => { setSelectedPerson(POIs[0]) }, [POIs])

    return (
        <LAYOUT_DEFAULT pageName={ "b_e_n_p" }>
            <NAVIGATION_PAGE_ID pageName={ `People` } hierarchy={ [`Dashboard`, `People`] }>
                <Link to={ `/add_person` } className="cta add">
                    <div className="icon add"></div>
                    Add Person
                </Link>
            </NAVIGATION_PAGE_ID>
            <NAVIGATION_FILTER_BAR>
                <div className="search">
                    <FORM_CONTAINER context={ context }>
                        <div className="fieldset">
                            <BUTTON icon={ `search` } callback={ HANDLER_SEARCH } />
                            <INPUT_FIELD type={ `search` } name={ `input_search` } placeholder={ `Search Vendors` } />
                        </div>
                    </FORM_CONTAINER>
                </div>
                <div className="ctas">
                    <div className="cta">
                        <div className="icon filter"></div>
                        Filters
                    </div>
                </div>
            </NAVIGATION_FILTER_BAR>
            <section className="data">
                <div className="list">
                    {
                        POIs.length > 0 ? POIs?.map((person, key) => {
                            return (
                                <div key={ key } onClick={ () => HANDLER_PERSON_CLICK(key) }>
                                    <CARD_B_E_N_P name={ person?.name } selected={ selectedPerson?._id === person?._id ? true : false }>
                                        <div className="role">{ person?.role }</div>
                                    </CARD_B_E_N_P>
                                </div>
                            )
                        }) : null
                    }
                    
                    <NAVIGATION_PAGINATION min={ true } callback={ HANDLER_FETCH_BATCH } currentPage={ currentPage } size={ 15 } total={ POIs?.length } />
                </div>
                
                <div className="details">
                    {
                        selectedPerson ?     
                        <>
                            <div className="banner">
                                {
                                    selectedPerson?.photoUrl ? <img src={ selectedPerson?.photoUrl } alt="" /> :
                                    <div className="no-pic">{ selectedPerson?.name[0].toUpperCase() }</div>
                                }
                            </div>
                            <div className="ctas">
                                <div className="cta" onClick={ () => HANDLER_DELETE_PERSON(selectedPerson?._id )}>Delete Person</div>
                                <div className="cta" onClick={ HANDLER_UPDATE_PERSON }>Update Person</div>
                            </div>
                            <div className="brief">
                                <B_E_N_P_DETAILS_TOPIC label="Name" value={ selectedPerson?.name }/>
                                <B_E_N_P_DETAILS_TOPIC label="Role" value={ selectedPerson?.type.replace(`_`, ` `) }/>
                                <B_E_N_P_DETAILS_TOPIC label="Description" value={ selectedPerson?.description }/>
                                <B_E_N_P_DETAILS_TOPIC label="Contact" value={ selectedPerson?.contact }/>
                                <B_E_N_P_DETAILS_TOPIC label="Occupation" value={ selectedPerson?.occupation }/>
                            </div>
                        </> : null
                    }
                    
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}