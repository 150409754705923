// third-party components and hooks
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// styles
import "../styles/page_b_e_n_p.css";
import "../styles/page_events.css";

// components
import { CARD_B_E_N_P } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_FILTER_BAR, NAVIGATION_PAGE_ID, NAVIGATION_PAGINATION } from "../components/navigations";
import { B_E_N_P_DETAILS_TOPIC } from "../components/shared";
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";

// reducers
import { delete_event, get_events, toggle_event_publishing } from "../integration/reducers/event";

export default function PAGE_EVENTS () {
    // state holders
    const context = useState();
    const [ currentPage, setCurrentPage ] = useState(1);
    const dispatch = useDispatch();
    const { events } = useSelector((state) => state.event);
    const [ selectedEvent, setSelectedEvent ] = useState(null)

    // handlers
    const HANDLER_DELETE_EVENT = (id) => {
        dispatch(delete_event(id))
        dispatch(get_events())
    }
    const HANDLER_FETCH_BATCH = (pageNumber) => {
        // fetch batch code here
        return setCurrentPage(pageNumber)
    }
    const HANDLER_EVENT_CLICK = (key) => { return setSelectedEvent(events[key]) }
    const HANDLER_SEARCH = (action) => {
        action.preventDefault();
    }
    const HANDLER_TOGGLE_EVENT_STATUS = () => {
        return dispatch(toggle_event_publishing(selectedEvent?._id))
    }
    const HANDLER_UPDATE_EVENT = (action) => {
        sessionStorage.setItem("eventDetails", JSON.stringify(selectedEvent));
        return window.location = `/update_event/:${ selectedEvent?._id }`
    }

    // watchers
    // eslint-disable-next-line
    useEffect(() => { dispatch(get_events({ page: 1, size: 15 })) }, [])
    // eslint-disable-next-line
    useEffect(() => { dispatch(get_events({ page: currentPage, size: 15 })) }, [currentPage])
    // eslint-disable-next-line
    useEffect(() => { setSelectedEvent(events[0]) }, [events])

    console.log(`events: `, events);

    return (
        <LAYOUT_DEFAULT pageName={ "b_e_n_p" }>
            <NAVIGATION_PAGE_ID pageName={ `Events` } hierarchy={ [`Dashboard`, `Events`] }>
                <Link to={ `/add_event` } className="cta add">
                    <div className="icon add"></div>
                    Add Events
                </Link>
            </NAVIGATION_PAGE_ID>
            <NAVIGATION_FILTER_BAR>
                <div className="search">
                    <FORM_CONTAINER context={ context }>
                        <div className="fieldset">
                            <BUTTON icon={ `search` } callback={ HANDLER_SEARCH } />
                            <INPUT_FIELD type={ `search` } name={ `input_search` } placeholder={ `Search Vendors` } />
                        </div>
                    </FORM_CONTAINER>
                </div>
                <div className="ctas">
                    <div className="cta">
                        <div className="icon filter"></div>
                        Filters
                    </div>
                </div>
            </NAVIGATION_FILTER_BAR>
            <section className="data">
                <div className="list">
                    {
                        events.length > 0 ? events?.map((event, key) => {
                            return (
                                <div key={ key } onClick={ () => HANDLER_EVENT_CLICK(key) }>
                                    <CARD_B_E_N_P name={ event?.name } selected={ selectedEvent?._id === event?._id ? true : false }>
                                        { event?.date ? <div className="date">{ event?.date }</div> : null }
                                        <div className={ `status ${ event?.status }` }>{ event?.status }</div>
                                    </CARD_B_E_N_P>
                                </div>
                            )
                        }) : null
                    }
                    
                    <NAVIGATION_PAGINATION min={ true } callback={ HANDLER_FETCH_BATCH } currentPage={ currentPage } size={ 15 } total={ events?.length } />
                </div>
                
                <div className="details">
                    {
                        selectedEvent ?
                        <>
                            <div className="banner">
                                {
                                    selectedEvent?.gallery?.length > 0 ?
                                    <img src={ selectedEvent?.gallery[0]?.securePhotoUrl } alt="" /> :
                                    <div className="no-pic">{ selectedEvent?.name?.toUpperCase() }</div>
                                }
                            </div>
                            <div className="ctas">
                                <div className="cta" onClick={ () => HANDLER_DELETE_EVENT(selectedEvent?._id) }>Delete Event</div>
                                <div className="cta" onClick={ HANDLER_UPDATE_EVENT }>Update Event</div>
                                <div className="cta" onClick={ HANDLER_TOGGLE_EVENT_STATUS }>{ selectedEvent?.isPublished ? `Unpublish` : `Publish` }</div>
                            </div>
                            <div className="brief">
                                <B_E_N_P_DETAILS_TOPIC label="Name" value={ selectedEvent?.name } />
                                <B_E_N_P_DETAILS_TOPIC label="Status" value={ selectedEvent?.status?.replace(`_`, ` `) } />
                                <B_E_N_P_DETAILS_TOPIC label="Description" value={ selectedEvent?.description } />
                                <B_E_N_P_DETAILS_TOPIC label="Occurence" value={ selectedEvent?.occurrenceType?.replace(`_`, ` `) } />
                                <B_E_N_P_DETAILS_TOPIC label="Location" type={ `resolve` } value={ `Empty` }/>
                                <B_E_N_P_DETAILS_TOPIC label="Tags" type={ `list` } value={ `Empty` }/>
                                <B_E_N_P_DETAILS_TOPIC label="Organisers" type={ `list` } value={ `Empty` }/>
                            </div>
                            {
                                selectedEvent?.gallery[0]?.length > 0 ?
                                <div className="gallery">
                                    {
                                        selectedEvent?.gallery[0]?.map((image, key) => {
                                            return (
                                                <img key={ key } src={ image?.securePhotoUrl } alt="gallery" />
                                            )
                                        })
                                    }
                                </div> : null
                            }
                        </> : null
                    }
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}