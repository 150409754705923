// third-party components and hooks
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import { CARD_A_N_V } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { NAVIGATION_FILTER_BAR, NAVIGATION_PAGE_ID, NAVIGATION_PAGINATION } from "../components/navigations";
import { BUTTON, FORM_CONTAINER, INPUT_FIELD } from "../components/form_elements";

// reducers
import { get_vendors } from "../integration/reducers/user";

export default function PAGE_VENDORS () {
    // state holders
    const context = useState();
    const [ currentPage, setCurrentPage ] = useState(1);
    const dispatch = useDispatch();
    const { vendors } = useSelector((state) => state.user);

    // handlers
    const HANDLER_FETCH_BATCH = (pageNumber) => {
        // fetch batch code here
        return setCurrentPage(pageNumber)
    }
    const HANDLER_SEARCH = (action) => {
        action.preventDefault();
    }

    // watchers
    // eslint-disable-next-line
    useEffect(() => { dispatch(get_vendors()) }, [])

    return (
        <LAYOUT_DEFAULT pageName={ "a_n_v" }>
            <NAVIGATION_PAGE_ID pageName={ `Vendors` } hierarchy={ [`Dashboard`, `Vendors`] }>
                <div className="cta export">
                    <div className="icon export"></div>
                    Export
                </div>
                <Link to={ `/add_vendor` } className="cta add">
                    <div className="icon add"></div>
                    Add Vendor
                </Link>
            </NAVIGATION_PAGE_ID>
            <NAVIGATION_FILTER_BAR>
                <div className="search">
                    <FORM_CONTAINER context={ context }>
                        <div className="fieldset">
                            <BUTTON icon={ `search` } callback={ HANDLER_SEARCH } />
                            <INPUT_FIELD type={ `search` } name={ `input_search` } placeholder={ `Search Vendors` } />
                        </div>
                    </FORM_CONTAINER>
                </div>
                <div className="ctas">
                    <div className="cta">
                        <div className="icon filter"></div>
                        Filters
                    </div>
                </div>
            </NAVIGATION_FILTER_BAR>
            <section className="data">
                {
                    vendors?.length > 0 ?
                    <>
                        <CARD_A_N_V heading={ true }/>
                        {
                            vendors?.map((vendor, key) => {
                                return (
                                    <div key={ key }>
                                        <CARD_A_N_V id={ vendor?._id }
                                                entity={{
                                                    name: vendor?.name,
                                                    email: vendor?.user?.fullName
                                                }} phone={ vendor?.user?.phoneNumber } dateCreated={ vendor?.user?.createdAt?.split(`T`) } />
                                    </div>
                                )
                            })
                        }
                        <NAVIGATION_PAGINATION callback={ HANDLER_FETCH_BATCH } currentPage={ currentPage } size={ 15 } total={ vendors?.length } />
                    </>
                    : <div>No Vendors Yet</div>
                }
            </section>
        </LAYOUT_DEFAULT>
    )
}