// third-party components and hooks
import { useDispatch } from "react-redux"

// styles
import "../styles/cards.css"

// reducers
import { sign_out } from "../integration/reducers/user"

// attendee and vendors card
export function CARD_A_N_V (props) {
    // handlers
    function HANDLER_DELETE (id) {}
    function HANDLER_EDIT (id) {}
    function HANDLER_STATUS_UPDATE (id) {}

    return (
        <>
            {
                props?.heading ?
                <div className="card anv header">
                    <div className="selector"></div>
                    <div className="object">NAME</div>
                    <div className="phone">PHONE NUMBER</div>
                    <div className="date-created">CREATED</div>
                    <div className="actions">ACTION</div>
                </div> :
                <div className="card anv">
                    <div className="selector"></div>
                    <div className="object">
                        {
                            props?.entity?.image ?
                            <div className="image"><img src={ props?.entity?.image } alt="" /></div> :
                            null
                        }
                        <div className="brief">
                            <div className="main">{ props?.entity?.name }</div>
                            <div className="sub">{ props?.entity?.email }</div>
                        </div>
                    </div>
                    <div className="phone">{ props?.phone }</div>
                    <div className="date-created">{ props?.dateCreated }</div>
                    <div className="actions">
                        <div className="icon card-edit" onClick={ () => HANDLER_EDIT(props?.id) }></div>
                        <div className="icon card-lock" onClick={ () => HANDLER_STATUS_UPDATE(props?.id) }></div>
                        <div className="icon card-trash" onClick={ () => HANDLER_DELETE(props?.id) }></div>
                    </div>
                </div>
            }
        </>
    )
}

export function CARD_CHART (props) {
    return (
        <div className="card chart">
            <div className="description">
                <div className="id">
                    <div className="main">{ props?.main }</div>
                    <div className="sub">{ props?.sub }</div>
                </div>
                <div className="legend">
                    <div className="c1">{ props?.c1 }</div>
                    <div className="c2">{ props?.c2 }</div>
                </div>
                <div className="options">
                    <div className="trigger">
                        <div className="icon three-dots"></div>
                    </div>
                    <div className="content">{ props?.c2 }</div>
                </div>
            </div>
            <div className="graph">
                the graph
            </div>
        </div>
    )
}

export function CARD_NOTIFICATION (props) {
    return (
        <div className={ `card notification ${ props?.selected ? `active` : null } ${ props?.status === `UNREAD` ? `unread` : null }` }>
            <div className="indi">
                <div className="indicator"></div>
                <div className="image">
                    {
                        props?.senderPP ?
                        <img src={ props?.senderPP } alt="sender" /> :
                        <div className="no-pic">{ props?.sender[0].toUpperCase() }</div>
                    }
                </div>
            </div>
            <div className="details">
                <div className="title">{ props?.title ? props?.title : `Unspecified` }</div>
                {
                    props?.attachments ?
                    <div className="attachments">
                        {
                            props?.attachments?.map((attachment, key) => {
                                return (
                                    <div key={ key } className="attachment">{ attachment?.name }</div>
                                )
                            })
                        }
                    </div> : null
                }
                <div className="datetime">{ props?.dateTime}</div>
            </div>
        </div>
    )
}

export function CARD_ORDER (props) {
    // handlers
    function HANDLER_DELETE (id) {}
    function HANDLER_VIEW (id) {}

    return (
        <>
            {
                props?.heading ?
                <div className="card order header">
                    <div className="selector"></div>
                    <div className="id">ORDER ID</div>
                    <div className="object">PRODUCT</div>
                    <div className="date-created">DATE</div>
                    <div className="object">CUSTOMER</div>
                    <div className="total">TOTAL</div>
                    <div className="payment">PAYMENT</div>
                    <div className="status">STATUS</div>
                    <div className="actions">ACTION</div>
                </div> :
                    <div className="card order">
                    <div className="selector"></div>
                    <div className="id">{ props?.id }</div>
                    <div className="object">
                        {
                            props?.product?.image ?
                            <div className="image"><img src={ props?.product?.image } alt="" /></div> :
                            null
                        }
                        <div className="brief">
                            <div className="main">{ props?.product?.name }</div>
                            <div className="sub">{ props?.product?.others }</div>
                        </div>
                    </div>
                    <div className="date-created">{ props?.dateCreated }</div>
                    <div className="object">
                        {
                            props?.customer?.image ?
                            <div className="image"><img src={ props?.customer?.image } alt="" /></div> :
                            null
                        }
                        <div className="brief">
                            <div className="main">{ props?.customer?.name }</div>
                            <div className="sub">{ props?.customer?.email }</div>
                        </div>
                    </div>
                    <div className="total">{ props?.total }</div>
                    <div className="payment">{ props?.paymentMethod }</div>
                    <div className={ `status ${ props?.status.toLowerCase() }` }>{ props?.status }</div>
                    <div className="actions">
                        <div className="icon card-eye" onClick={ () => HANDLER_VIEW(props?.id) }></div>
                        <div className="icon card-trash-2" onClick={ () => HANDLER_DELETE(props?.id) }></div>
                    </div>
                </div>
            }
        </>
    )
}

// brands, events, and people card
export function CARD_B_E_N_P (props) {
    return (
        <div className={ `card benp ${ props?.selected ? `selected` : null }` }>
            <div className="name">{ props?.name }</div>
            { props?.children ? <div className="others">{ props?.children }</div> : null }
        </div>
    )
}

export function CARD_SUMMARY (props) {
    return (
        <div className="card summary">
            <div className="label">
                { props?.icon ? <div className={ `icon ${ props?.icon }` }></div> : null }
                <div>{ props?.label }</div>
            </div>

            { props?.banner ? <div className="banner"><img src={ props?.banner } alt="" /></div> : null }
            
            <div className="main">
                <div>{ props?.main }</div>
            </div>

            <div className="change">
                <div className={ `percentage ${ props?.percentageChange > 0 ? `up` : `down` }` }>
                    <div>{ props?.percentageChange }%</div>
                    { props?.percentageChange > 0 ? <div className="icon change-up"></div> : null }
                </div>
                <div className="actual">+{ props?.actualChange } today</div>
            </div>
        </div>
    )
}

export function CARD_USER (props) {
    // state holders
    const dispatch = useDispatch()

    // functions
    function dropOptions() { document.querySelector(`.card.user .options`)?.classList.toggle(`drop`); }

    return (
        <div className="card user">
            <div className="picture">
                {
                    props?.image ?
                    <img src={ props?.image } alt="user" /> :
                    <div className="no-pic">{ props?.name[0] }</div>
                }
                <div className="cta" onClick={ props?.updatePictureCallback }></div>
            </div>
            <div className="description">
                <div className="name">{ props?.name }</div>
                <div className="role">{ props?.role }</div>
            </div>
            <div className="options">
                <div className="icon card-user-caret" onClick={ dropOptions }></div>
                <div className="content">
                    <div>
                        <div className="icon profile"></div>
                        Update Profile
                    </div>
                    <div onClick={ () => dispatch(sign_out()) }>
                        <div className="icon logout"></div>
                        Sign Out
                    </div>
                </div>
            </div>
        </div>
    )
}