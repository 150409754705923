// data
import { NAVIGATION } from "../data/navigations"

// styles
import "../styles/layouts.css"

// components
import { CARD_USER } from "./cards"
import { NAVIGATION_PANEL, NAVIGATION_USER_CONTROL } from "./navigations"

export function LAYOUT_AUTH ({ children, pageName }) {
    return (
        <div id="page" className="layout auth" data-page-name={ pageName }>
            <div id="content">{ children }</div>
        </div>
    )
}

export function LAYOUT_DEFAULT ({ children, pageName }) {
    return (
        <div id="page" className="layout default" data-page-name={ pageName }>
            <div id="navigation"><NAVIGATION_PANEL routes={ NAVIGATION } /></div>
            <div id="content">
                <NAVIGATION_USER_CONTROL>
                    <div className="icon notifications"></div>
                    <CARD_USER />
                </NAVIGATION_USER_CONTROL>
                { children }
            </div>
        </div>
    )
}