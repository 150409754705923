// third-party components and hooks
import { useState } from "react"
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

// styles
import "../styles/navigations.css"

// components
import { CARD_NOTIFICATION, CARD_USER } from "./cards"

export function NAVIGATION_FILTER_BAR (props) {
    return (
        <div className="navigation" id="filter-bar">{ props?.children }</div>
    )
}

export function NAVIGATION_PAGE_ID ({ children, pageName, hierarchy }) {
    return (
        <div className="navigation" id="page-id">
            <div className="name">{ pageName }</div>
            <div className="extras">
                <div className="breadcrumb">{
                    hierarchy?.map((item, count) => {
                        return (
                            <div key={ count }>{ item }</div>
                        )
                    })
                }</div>
                <div className="ctas">{ children }</div>
            </div>
        </div>
    )
}

export function NAVIGATION_PAGINATION ({ callback, currentPage, min, size, total }) {
    const mapper = Array.from({ length: total / size > 5 ? 5 : null }, (_, index) => index + 1)

    return (
        <div className={ `navigation ${ min ? `min` : null }` } id="pagination">
            <div className="range">Showing { ((currentPage - 1) * size) + 1} - { total >= size ? currentPage * size : total } from { total }</div>
            {
                total <= size ? null:
                <div className="controls">
                    <div className="previous icon pagination-left"></div>
                    <div className="keys">
                        {
                            mapper?.map((_, key) => {
                                return (
                                    <div key={ key } onClick={ () => callback(currentPage + key) } className={ currentPage + key === currentPage ? `active` : null }>{ currentPage + key }</div>
                                )
                            })
                        }
                    </div>
                    <div className="next icon pagination-right"></div>
                </div>
            }
        </div>
    )
}

export function NAVIGATION_PANEL ({ routes }) {
    // lambda(s)
    const expandLinkCombo = (index) => { document.querySelector(`#panel .body .links`).children[index].classList.toggle("expanded") }
    const expandNav = () => { document.querySelector(`#panel`).classList.toggle("expanded") }

    return (
        <div className="navigation" id="panel">
            <div className="head">
                <div></div>
                <div className="menu" onClick={ expandNav }>MENU</div>
            </div>

            <div className="body">
                <div className="links">
                    {
                        routes?.map((navlink, index) => {
                            return (
                                navlink?.subs ?
                                <div className="link-combo" key={ index }>
                                    <div className="dom">
                                        <NavLink to={ { pathname: navlink?.path } } className="link" isactive="active">
                                            <div className={ `icon navigation-${ navlink?.id.replace("_", "-") }` }></div>
                                            { navlink?.name }
                                        </NavLink>
                                        <div className="controller icon navigation-caret" onClick={ () => expandLinkCombo(index) }></div>
                                    </div>
                                    <div className="subs">
                                        {
                                            navlink?.subs?.map((sub, subIndex) => {
                                                return (
                                                    <NavLink to={ { pathname: sub?.path } } className="link" isactive="active" key={ subIndex }>
                                                        <div className="icon"></div>
                                                        { sub?.name }
                                                    </NavLink>
                                                )
                                            })
                                        }
                                    </div>
                                </div> :
                                <NavLink to={ { pathname: navlink?.path } } className="link" isactive="active" key={ index }>
                                    <div className={ `icon navigation-${ navlink?.id.replace("_", "-") }` }></div>
                                    { navlink?.name }
                                </NavLink>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export function NAVIGATION_USER_CONTROL ({ children }) {
    // state holders
    const [ notificationsWindowIsOpen, setNotificationsWindowIsOpen ] = useState(false);
	const { authenticatedUser } = useSelector((state) => state.user);

    // handlers
    const HANDLER_NOTIFICATIONS_MODAL_TOGGLER = () => {setNotificationsWindowIsOpen((prev) => !prev)}

    return (
        <div className="navigation" id="user-conrol">
            <div className="icon navigation-notifications" data-badge={ `3` } onClick={ HANDLER_NOTIFICATIONS_MODAL_TOGGLER }></div>
            <CARD_USER image={ null } name={ `${ authenticatedUser?.data?.firstName } ${ authenticatedUser?.data?.lastName }` } role={ `` } />
            

            {
                notificationsWindowIsOpen ?
                <div id="modal" className="notifications">
                    <div className="title">
                        <div>Notifications</div>
                        <div onClick={ HANDLER_NOTIFICATIONS_MODAL_TOGGLER }>x</div>
                    </div>
                    <div className="controls">
                        <div>Mark All as Read</div>
                    </div>
                    <div className="data">
                        <div className="list">
                            <CARD_NOTIFICATION selected={ true } status={ `UNREAD` } title={ `Title` } sender={ `Somebody` } senderPP={ null } attachments={ [
                                {
                                    name: `Some Document`
                                }
                            ] } dateTime={ `1 min ago` } />
                            <CARD_NOTIFICATION status={ `UNREAD` } title={ `This has some long tile, to test overflow` } sender={ `Somebody` } senderPP={ null } attachments={ [
                                {
                                    name: `Some Document`
                                }
                            ] } dateTime={ `1 min ago` } />
                            <CARD_NOTIFICATION status={ `READ` } title={ `Title` } sender={ `Somebody` } senderPP={ null } attachments={ [] } dateTime={ `1 min ago` } />
                        </div>
                    </div>
                </div> : null
            }
        </div>
    )
}

export function NAVIGATION_PROTECTED_ROUTE () {
    // state holders
    const location = useLocation()
    const { authenticatedUser } = useSelector((state) => state.user)

    if (authenticatedUser) {
        return (
            <>
                {
                    authenticatedUser?.statusCode >= 400 ?
                    <Navigate to={ `/` }></Navigate> :
                    (location.pathname === `/sign_in` || location.pathname === `/`) ?
                    <Navigate to={ `/dashboard` }></Navigate> : <Outlet />
                }
            </>
        )
    }
    
    return ( <Navigate to={ `/` }></Navigate> )
}