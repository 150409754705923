// third-party components and hooks
import { useSelector } from "react-redux";

// styles
import "../styles/page_dashboard.css"

// components
import { CARD_CHART, CARD_SUMMARY } from "../components/cards";
import { LAYOUT_DEFAULT } from "../components/layouts";
import { DATE_RANGES } from "../components/shared";

export default function PAGE_DASHBOARD () {
    // state holders
    const { authenticatedUser } = useSelector((state) => state.user)

    return (
        <LAYOUT_DEFAULT pageName={ "dashboard" }>
            <div className="intro">Welcome Back { authenticatedUser?.name }</div>
            
            <DATE_RANGES>
                <div className="active">All Date</div>
                <div>12 Months</div>
                <div>30 Days</div>
                <div>7 Days</div>
                <div>24 Hours</div>
            </DATE_RANGES>

            <section className="briefing">
                <CARD_SUMMARY icon={ `card-summary-payment` } label={ `Total Payments Made` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-payment` } label={ `Total Payouts` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-order` } label={ `Orders` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-order` } label={ `Total Products Added` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-person` } label={ `Attendees` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-person` } label={ `Active Attendees` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-person` } label={ `Vendors` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
                <CARD_SUMMARY icon={ `card-summary-person` } label={ `Active Vendors` } main={ `$75,000` } percentageChange={ `10` } actualChange={ `150` } />
            </section>
            <section className="charts">
                <CARD_CHART main={ `Vendors` } sub={ `Income and Expenses` } c1={ `Revenue` } c2={ `Sales` } />
                <CARD_CHART main={ `Attendees` } sub={ `Income and Expenses` } c1={ `Revenue` } c2={ `Sales` } />
            </section>
        </LAYOUT_DEFAULT>
    )
}